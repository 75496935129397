import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Lightbox from "../lightbox";

class P25Showcase extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allFile(
                            filter: {
                                extension: { regex: "/(jpg)/" }
                                relativeDirectory: {
                                    eq: "portfolio/P1.904 Indoor"
                                }
                            }
                            sort: { fields: base, order: ASC }
                        ) {
                            nodes {
                                base
                                childImageSharp {
                                    fluid(quality: 70) {
                                        ...GatsbyImageSharpFluid
                                        originalImg
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => <Lightbox productImages={data.allFile.nodes} />}
            />
        );
    }
}

export default P25Showcase;
