import PropTypes from "prop-types";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Layout from "../../../components/layout";
import OrderButton from "../../../components/orderbutton";
import P1904Showcase from "../../../components/product/indoor/p1904Showcase";
import ProductTable from "../../../components/productTable";
import SEO from "../../../components/seo";
import Content from "../../../content/produk/indoor/P1.904/Product-Page.json";
import ProductImage from "../../../images/product/indoor/P1.904/Product-Summary-P1.904-Indoor.jpg";
import "../../../styles/product.css";

const P1904 = ({ siteTitle }) => {
    const headerStyle = {
        textAlign: "center",
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        paddingBottom: "20px",
        position: "relative",
        color: "#2d405f",
    };
    return (
        <Layout>
            <SEO
                title={Content.seo.title}
                description={Content.seo.description}
                lang={Content.seo.lang}
            />
            <section id={"produk"} className={"section-bg"}>
                <Container>
                    <div className={"section-title"}>
                        <h1>P1.904 Indoor LED Display Module</h1>
                        <Row>
                            <Col>
                                <Image
                                    src={ProductImage}
                                    fluid
                                    alt={"Gambar modul"}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col sm={true}>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Product Summary
                                    </h2>
                                </div>
                                <p style={{ textAlign: "justify" }}>
                                    P1.904 indoor merupakan layar LED penuh
                                    warna dengan kepadatan piksel yang sangat
                                    tinggi sehingga yang cocok digunakan pada{" "}
                                    <i>creative space</i>, <i>indoor stage</i>,{" "}
                                    <i>hall</i>, tempat belajar mengajar, acara
                                    atau kegiatan yang perlu media yang membantu
                                    penyampaian informasi dengan baik
                                    (informatif) seperti seminar,{" "}
                                    <i>meeting room</i> (ruang pertemuan) dan
                                    acara-acara dalam ruang <i>(indoor)</i>{" "}
                                    lainnya. P1.904 memiliki kualitas sebagai
                                    berikut:
                                </p>
                                <ul style={{ textAlign: "left" }}>
                                    <li>
                                        <i>Refresh rate</i> yang baik
                                    </li>
                                    <li>
                                        Kepadatan piksel yang tinggi sehingga
                                        menghasilkan visual yang detail dan
                                        menarik
                                    </li>
                                    <li>
                                        Media yang inovatif, interaktif,
                                        informatif untuk berbagai keperluan.
                                    </li>
                                    <li>Struktur yang kuat dan tahan lama.</li>
                                </ul>
                                <p>
                                    <OrderButton
                                        messageText={Content.messageText}
                                    />
                                </p>
                            </Col>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Unit Module
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"Unit Module"}
                                    tableData={Content.moduleUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        LED Cabinet Unit
                                    </h2>
                                </div>
                                <ProductTable
                                    tableName={"LED Unit Cabinet"}
                                    tableData={Content.cabinetUnit}
                                />
                            </Col>
                        </Row>
                        <Row className={"mt-5"}>
                            <Col>
                                <div className={"section-title"}>
                                    <h2 className={"text-center"}>
                                        Contoh Pemasangan P1.904 Indoor
                                    </h2>
                                </div>
                                <P1904Showcase />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </Layout>
    );
};

P1904.propTypes = {
    siteTitle: PropTypes.string,
};

P1904.defaultProps = {
    siteTitle: ``,
};

export default P1904;
